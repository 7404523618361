import { FunctionComponent, useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import './App.scss';
import Main from './components/Main';
import { 
    Header,
    HeaderName,
    HeaderGlobalBar,
    HeaderGlobalAction,
} from 'carbon-components-react';
import { FirebaseDatabaseProvider } from "@react-firebase/database";

//@ts-expect-error
import { Logout20 } from '@carbon/icons-react';

// Configure Firebase.
const config = {
    apiKey: "AIzaSyA5UMo-ilV6fhuOxn7Y06pPjODNfMor8e8",
    authDomain: "syns-platform-dev.firebaseapp.com",
    projectId: "syns-platform-dev",
    databaseURL: "https://syns-platform-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    appId: "1:532769535270:web:e0d23f999c0428d443fde7",
    measurementId: "G-L0X8P9XSWV"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.analytics();
}

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
};

const App: FunctionComponent = () => {
    const [isSignedIn, setIsSignedIn] = useState(false);

    const signOut = () => {
        firebase.auth().signOut()
            .then(() => setIsSignedIn(false))
            .catch(alert)
    }
    
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user: any) => {
            setIsSignedIn(user && user.email.indexOf("@synspective.com") >= 0);
        });
        
        return () => unregisterAuthObserver();
    }, []);
    
    return (
        <div id="app">
        {isSignedIn ? 
            <>
                <Header aria-label="Synspective">
                    <HeaderName href="#" prefix="Synspective Platform">
                    Service Documentation &amp; Monitoring
                    </HeaderName>
                    <HeaderGlobalBar>
                        <HeaderGlobalAction aria-label="Logout" onClick={signOut}>
                            <Logout20 />
                        </HeaderGlobalAction>
                    </HeaderGlobalBar>
                </Header>
                ));
                <FirebaseDatabaseProvider firebase={firebase}>
                    <Main></Main>
                </FirebaseDatabaseProvider>
            </>
            : 
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        } 
        </div>
    );
}

export default App;

{/* <Logout32 /> */}
