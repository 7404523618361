import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Stage, Layer, Rect, Arrow, Text, Group, Circle } from 'react-konva';
import { map, startsWith, without } from 'lodash';
import Konva from 'konva';
import { KonvaEventObject, Node as KonvaNode } from 'konva/lib/Node';
import { 
    Tabs,
    Tab,
} from 'carbon-components-react';
import {
    NODE_DEFAULT_DIMENSION,
    calculateArrowPoints,
    changeCursor,
    Coordinate,
} from '../utils/graph'
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

//@ts-expect-error
import { TrashCan20 } from '@carbon/icons-react';

class NodeContent {
    text: string
    constructor() {
        this.text = ""
    }
}

class Node {
    id: string
    x: number
    y: number
    w: number
    h: number
    style?: any
    content: NodeContent
    from?: Array<string>
    to?: Array<string>
    constructor() {
        this.id = ""
        this.x = 0
        this.y = 0
        this.w = 0
        this.h = 0
        this.content = new NodeContent
    }
}

interface Nodes {
    [name: string]: Node
}

var setNodeArrowHandlerPosition = (arw: KonvaNode, node: KonvaNode) => {
    var pos = arw.attrs.id.substr(8)
    var lyr = node.getLayer()
    if (lyr) {
        switch (pos) {
            case "left":
                arw.position({x: 0, y: node.height()/2})
                break;
            case "right":
                arw.position({x: node.width(), y: node.height()/2})
                break;
            case "top":
                arw.position({x: node.width()/2, y: 0})
                break;
            case "bottom":
                arw.position({x: node.width()/2, y: node.height()})
                break;
        }
    }
}

var nodeHitFunc = function(this: KonvaNode, context: any) {
    context.beginPath();
    context.rect(-5, -5, this.width()+10, this.height()+10);
    context.closePath();
    context.fillStrokeShape(this);
}

var onNodeArrowHandlerMouseEnter = (evt: KonvaEventObject<MouseEvent>) => {
    if (evt.currentTarget instanceof Konva.Circle) {
        changeCursor(evt.currentTarget, "crosshair")
        evt.currentTarget.visible(true)
        evt.currentTarget.fill("#B40000")         
    }
}

var onNodeArrowHandlerMouseLeave = (evt: KonvaEventObject<MouseEvent>) => {
    if (evt.currentTarget instanceof Konva.Circle) {
        changeCursor(evt.currentTarget, "default")
        evt.currentTarget.fill("#fff")
    }
}

var drawArrowSourceNode: Konva.Group | null = null
var drawArrowDestinationNode: Konva.Group | null = null

const Main: FunctionComponent = () => {
    const [editable, setEditable] = useState(false)
    const [showSidePanel, setShowSidePanel] = useState(false)
    const [sidePanelContent, setSidePanelContent] = useState<Node>(new Node)
    const [nodes, setNodes] = useState<Nodes>({})
    const [isMouseDownStage, setIsMouseDownStage] = useState(false)
    const [layerDragOriginalPos, setLayerDragOriginalPos] = useState({mouseX: 0, mouseY: 0, layerX: 0, layerY: 0})
    const [layerOffset, setLayerOffset] = useState<Coordinate>({x: 0, y:0})
    const nodeArrowHandlers = useRef<Konva.Group>(null)

    var onMouseEnterNode = (evt: Konva.KonvaEventObject<MouseEvent>) => {
        changeCursor(evt.currentTarget, "pointer")
        var grp: Konva.Group | null = nodeArrowHandlers.current
        if (grp != null && evt.currentTarget instanceof Konva.Group && editable) {
            drawArrowSourceNode = evt.currentTarget
            grp.position(evt.currentTarget.position())
            grp.width(evt.currentTarget.width())
            grp.height(evt.currentTarget.height())
            grp.children?.forEach((child) => {
                if (child instanceof Konva.Circle && startsWith(child.attrs.id, "addnode")) {
                    child.visible(true)
                    setNodeArrowHandlerPosition(child, evt.currentTarget)
                }
            })
        }
    }

    var onMouseLeaveNode = (evt: Konva.KonvaEventObject<MouseEvent>) => {
        changeCursor(evt.currentTarget, "default")
        var grp: Konva.Group | null = nodeArrowHandlers.current
        if (grp != null) {
            grp.children?.forEach((child) => {
                child.visible(false) 
            })
        }
    }

    // TODO: This is also used by stage dblclick event which
    // will even capture quick consecutive clicks on two points 
    // far apart from each other. Will need to handle this manually 
    // instead to constraint double click detection to the same general 
    // location (within 10 units radius).
    var addNewNode = (evt: KonvaEventObject<MouseEvent>) => {
        if (!editable) {
            return
        }

        evt.cancelBubble = true
        var newNodes = Object.assign({}, nodes)
        var id = uuidv4()

        // add new node
        newNodes[id] = {
            id: id,
            x: evt.evt.x - layerOffset.x - (NODE_DEFAULT_DIMENSION.w/2),
            y: evt.evt.y - layerOffset.y - (NODE_DEFAULT_DIMENSION.h/2),
            w: NODE_DEFAULT_DIMENSION.w,
            h: NODE_DEFAULT_DIMENSION.h,
            style: {
                fill: "white"
            },
            content: {
                text: "New Node"
            }
        }

        setNodes(newNodes)
        firebase.database().ref('nodegraph').set(newNodes);
    }

    const onMouseDownStage = (evt: Konva.KonvaEventObject<MouseEvent>) => {
        if (evt.currentTarget instanceof Konva.Stage && evt.evt.button == 1) {
            var stg: Konva.Stage = evt.currentTarget
            var lyr: Konva.Layer = stg.findOne("#main")

            if (lyr && lyr instanceof Konva.Layer) {
                setIsMouseDownStage(true)
                setLayerDragOriginalPos({
                    mouseX: evt.evt.x, 
                    mouseY: evt.evt.y,
                    layerX: lyr.x(),
                    layerY: lyr.y(),
                })
            }
        }
    }
    
    const onMouseMoveStage = (evt: Konva.KonvaEventObject<MouseEvent>) => {
        if (isMouseDownStage && evt.currentTarget instanceof Konva.Stage) {
            var stg: Konva.Stage = evt.currentTarget
            var lyr: Konva.Layer = stg.findOne("#main")
            if (lyr && lyr instanceof Konva.Layer) {
                lyr.absolutePosition({
                    x: evt.evt.x - layerDragOriginalPos.mouseX + layerDragOriginalPos.layerX,
                    y: evt.evt.y - layerDragOriginalPos.mouseY + layerDragOriginalPos.layerY
                })
            }
        }
    }

    const onMouseUpStage = (evt: Konva.KonvaEventObject<MouseEvent>) => {
        if (isMouseDownStage && evt.currentTarget instanceof Konva.Stage) {
            setIsMouseDownStage(false)

            var stg: Konva.Stage = evt.currentTarget
            var lyr: Konva.Layer = stg.findOne("#main")
            if (lyr && lyr instanceof Konva.Layer) {
                setLayerOffset({x: lyr.x(), y: lyr.y()})
            }
        }
    }
    
    var onDragMoveNode = (ele: any) => {
        var node: Konva.Group = ele.currentTarget
        node.children?.forEach(child => {
            if (child instanceof Konva.Arrow) {
                let fromTo = child.attrs.id.split("=")[1].split(":")
                
                if (startsWith(child.attrs.id, "to=")) {
                    child.points(calculateArrowPoints(node, nodes[fromTo[1]]))
                } else if (startsWith(child.attrs.id, "from=")) {
                    var arw: Konva.Arrow | undefined = child.getStage()?.findOne(`#to=${fromTo[0]}:${fromTo[1]}`)
                    if (arw) {
                        arw.points(calculateArrowPoints(nodes[fromTo[0]], node))
                    }
                } 
            }
        })
    }
    
    var onDragEndNode = (ele: KonvaEventObject<DragEvent>) => {
        if (ele.currentTarget.attrs?.id) {
            var id = ele.currentTarget.attrs.id.substr(6)
            var newNodes = Object.assign({}, nodes)
            newNodes[id].x = ele.currentTarget.x()
            newNodes[id].y = ele.currentTarget.y()
            setNodes(newNodes)
            firebase.database().ref('nodegraph').set(newNodes);
        }
    }
    
    var onClickNode = (evt: Konva.KonvaEventObject<MouseEvent>) => {
        var id = evt.currentTarget.attrs.id.substr(6)
        setSidePanelContent(nodes[id])
        setShowSidePanel(true)
    }

    var onNodeNameChange = (evt: React.FormEvent<HTMLInputElement>) => {
        if (!sidePanelContent) {
            return
        }

        sidePanelContent.content.text = evt.currentTarget.value
        var newNodes = Object.assign({}, nodes)
        newNodes[sidePanelContent.id] = sidePanelContent
        setNodes(newNodes)
        firebase.database().ref('nodegraph').set(newNodes);
    }

    var onDragNodeArrowHandlerMove = (evt: KonvaEventObject<MouseEvent>) => {
        // manually check drop
        let lyr = evt.currentTarget.getLayer()
        const pos = evt.target.getStage()?.getPointerPosition()
        if (lyr && lyr.children && pos) {
            let found = false
            for (let i in lyr.children) {
                var grp: Konva.Group | Konva.Shape = lyr.children[i]
                if (lyr != null) {
                    const bound = [
                        lyr.x() + grp.x(),
                        lyr.y() + grp.y(),
                        lyr.x() + grp.x() + grp.width(),
                        lyr.y() + grp.y() + grp.height()
                    ]
                    
                    if (grp instanceof Konva.Group) {
                        var hover: Konva.Rect = grp.findOne(".hover")

                        if (hover) {
                            hover.visible(false)
                        }

                        if (grp != drawArrowSourceNode &&
                            pos.x >= bound[0] && pos.y >= bound[1] && 
                            pos.x <= bound[2] && pos.y <= bound[3]) 
                        {
                            var rect: Konva.Rect = grp.findOne(".node")
                            if (rect) {
                                drawArrowDestinationNode = grp
                                hover.visible(true)
                                found = true
                                break
                            }
                        }
                    }
                }
            }

            if (!found) {
                drawArrowDestinationNode = null
            }
        }

        let arw = evt.currentTarget.getParent().findOne("#arrow")
        if (arw instanceof Konva.Arrow && drawArrowSourceNode) {
            arw.visible(true)
            arw.points(calculateArrowPoints(drawArrowSourceNode, {
                x: evt.currentTarget.x() + evt.currentTarget.getParent().x(),
                y: evt.currentTarget.y() + evt.currentTarget.getParent().y(),
                w: 0,
                h: 0,
            }))
        }
    }

    var onDragNodeArrowHandlerEnd = (evt: KonvaEventObject<MouseEvent>) => {
        changeCursor(evt.currentTarget, "default")
        var grp: Konva.Group | null = nodeArrowHandlers.current
        if (grp != null) {
            grp.children?.forEach((child) => {
                child.visible(false)
            })
        }

        if (drawArrowSourceNode && drawArrowDestinationNode) {
            var hover: Konva.Node = drawArrowDestinationNode.findOne(".hover")
            if (hover) {
                hover.visible(false)
            }


            let fromId = drawArrowSourceNode.attrs.id.substr(6)
            let toId = drawArrowDestinationNode.attrs.id.substr(6)

            // Update node edges
            let newNodes = Object.assign({}, nodes)
            let from = newNodes[fromId]
            let to = newNodes[toId]
            if (from && to) {
                if (!from.to) {
                    from.to = []
                }

                if (!to.from) {
                    to.from = []
                }
                
                to.from.push(fromId)
                from.to.push(toId)
            }
            setNodes(newNodes)
            firebase.database().ref('nodegraph').set(newNodes);
        }
    }
    
    var onClickStage = (evt: KonvaEventObject<MouseEvent>) => {
        if (evt.currentTarget instanceof Konva.Stage && evt.target == evt.currentTarget) {
            setSidePanelContent(new Node)
            setShowSidePanel(false)
        }
    }

    var onClickDelete = () => {
        if (sidePanelContent) {
            let newNodes = Object.assign({}, nodes)
            let node = newNodes[sidePanelContent.id]
            node.to?.forEach((toId) => {
                newNodes[toId].from = without(newNodes[toId].from, sidePanelContent.id)
            })

            node.from?.forEach((fromId) => {
                newNodes[fromId].to = without(newNodes[fromId].to, sidePanelContent.id)
            })

            delete newNodes[sidePanelContent.id]
            setNodes(newNodes)
            firebase.database().ref('nodegraph').set(newNodes);

            setShowSidePanel(false)
            setSidePanelContent(new Node)
        }
    }

    var getNodeGraph = () => {
        firebase.database().ref('nodegraph')
            .on('value', snapshot => setNodes(snapshot.val()));
    }
    
    useEffect(() => {
        getNodeGraph()
    }, [])
    
    return (
        <>
            <div 
                id="sidepanel" 
                className={showSidePanel ? "show": ""} 
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
            >
                <div className="actionbar">
                    { editable ? <div onClick={onClickDelete}><TrashCan20 /></div> : "" }
                </div>
                <div className="header">
                    <input 
                        type="text" 
                        className="headerinput" 
                        value={sidePanelContent.content.text} 
                        onInput={onNodeNameChange}
                    />
                    <Tabs>
                        <Tab id="tab-1" label="Overview">
                            <p>Coming Soon!</p>
                        </Tab>
                        <Tab id="tab-2" label="GCP Resources">
                        </Tab>
                    </Tabs>
                </div>
                <div className="content">
                </div>
            </div>
            
            <Stage 
                width={window.innerWidth} 
                height={window.innerHeight} 
                onClick={onClickStage}
                onMouseDown={onMouseDownStage}
                onMouseUp={onMouseUpStage}
                onMouseMove={onMouseMoveStage}
                onDblClick={addNewNode}
            >
                <Layer id="main">
                    {map(nodes, (node: Node) => 
                        <Group
                            key={`group-${node.id}`}
                            id={`group-${node.id}`}
                            name={node.content.text}
                            x={node.x}
                            y={node.y}
                            width={node.w}
                            height={node.h}
                            draggable={editable}
                            onMouseLeave={onMouseLeaveNode}
                            onMouseEnter={onMouseEnterNode}
                            onDragMove={onDragMoveNode}
                            onDragEnd={onDragEndNode}
                            onClick={onClickNode}
                        >
                            <Rect
                                name="hover"
                                x={0}
                                y={0}
                                width={node.w}
                                height={node.h}
                                fill=""
                                stroke="#6874E8"
                                strokeWidth={6}
                                cornerRadius={4}
                                visible={false}
                            >
                            </Rect>

                            <Rect
                                name="node"
                                x={0}
                                y={0}
                                width={node.w}
                                height={node.h}
                                fill={node.style?.fill}
                                stroke="#5e5e5e"
                                strokeWidth={2}
                                cornerRadius={4}
                                hitFunc={nodeHitFunc}
                            >
                            </Rect>
                            
                            <Text
                                x={0}
                                y={0}
                                width={node.w}
                                height={node.h}
                                text={node.content?.text}
                                fontSize={14}
                                fontFamily="Menlo, monospace"
                                fill="black"
                                ellipsis={true}
                                align="center"
                                verticalAlign="middle"
                            >
                            </Text>
                            
                            {map(node.to?.filter((toId: string) => nodes[toId]), (toId: string) => 
                                <Arrow
                                    key={`to=${node.id}:${toId}`}
                                    id={`to=${node.id}:${toId}`}
                                    x={0}
                                    y={0}
                                    points={calculateArrowPoints(node, nodes[toId])}
                                    pointerLength={10}
                                    pointerWidth={10}
                                    fill="#5e5e5e"
                                    stroke="#5e5e5e"
                                    strokeWidth={2}
                                    listening={false}
                                >
                                </Arrow>
                            )}
                                
                            {map(node.from?.filter((fromId: string) => nodes[fromId]), (fromId: string) => 
                                <Arrow
                                    key={`from=${fromId}:${node.id}`}
                                    id={`from=${fromId}:${node.id}`}
                                    x={0}
                                    y={0}
                                    points={[]}
                                    visible={false}
                                    pointerLength={0}
                                    pointerWidth={0}
                                    strokeWidth={0}
                                    listening={false}
                                    >
                                </Arrow>
                            )}
                        </Group>
                    )}

                    <Group ref={nodeArrowHandlers}
                        x={0}
                        y={0}
                        >
                        {/* Overlay icons to add new nodes */}
                        <Arrow
                            id="arrow"
                            x={0}
                            y={0}
                            points={[]}
                            pointerLength={10}
                            pointerWidth={10}
                            fill="#5e5e5e"
                            stroke="#5e5e5e"
                            strokeWidth={2}
                            visible={false}>
                            
                        </Arrow>

                        <Circle 
                            id="addnode-left"
                            width={10}
                            height={10}
                            fill="#fff"
                            stroke="#b40000"
                            strokeWidth={2}
                            visible={false}
                            onMouseEnter={onNodeArrowHandlerMouseEnter}
                            onMouseLeave={onNodeArrowHandlerMouseLeave}
                            draggable={true}
                            onDragEnd={onDragNodeArrowHandlerEnd}
                            onDragMove={onDragNodeArrowHandlerMove}
                        >
                        </Circle>
                        <Circle 
                            id="addnode-right"
                            width={10}
                            height={10}
                            fill="#fff"
                            stroke="#b40000"
                            strokeWidth={2}
                            visible={false}
                            onMouseEnter={onNodeArrowHandlerMouseEnter}
                            onMouseLeave={onNodeArrowHandlerMouseLeave}
                            draggable={true}
                            onDragEnd={onDragNodeArrowHandlerEnd}
                            onDragMove={onDragNodeArrowHandlerMove}
                        >
                        </Circle>
                        <Circle 
                            id="addnode-top"
                            width={10}
                            height={10}
                            fill="#fff"
                            stroke="#b40000"
                            strokeWidth={2}
                            visible={false}
                            onMouseEnter={onNodeArrowHandlerMouseEnter}
                            onMouseLeave={onNodeArrowHandlerMouseLeave}
                            draggable={true}
                            onDragEnd={onDragNodeArrowHandlerEnd}
                            onDragMove={onDragNodeArrowHandlerMove}
                        >
                        </Circle>
                        <Circle 
                            id="addnode-bottom"
                            width={10}
                            height={10}
                            fill="#fff"
                            stroke="#b40000"
                            strokeWidth={2}
                            visible={false}
                            onMouseEnter={onNodeArrowHandlerMouseEnter}
                            onMouseLeave={onNodeArrowHandlerMouseLeave}
                            draggable={true}
                            onDragEnd={onDragNodeArrowHandlerEnd}
                            onDragMove={onDragNodeArrowHandlerMove}
                        >
                        </Circle>
                    </Group>
                </Layer>
        </Stage>
    </>
    );
};

export default Main;
                