import Konva from "konva";
import { Node, NodeConfig } from "konva/lib/Node";

export const NODE_DEFAULT_DIMENSION = Object.freeze({w: 125, h: 75})
const ARROW_OUT = Object.freeze({TOP: 0, RIGHT: 1, BOTTOM: 2, LEFT: 3})
const nodeAnchorOffset = {x: 15, y: 15}

export interface Coordinate {
    x: number
    y: number
}

export function onlyUnique (value: any, index: any, self: any) {
    return self.indexOf(value) === index;
}

export function calculateArrowPoints(from: any, to: any): Array<number> {
    from = toValue(from)
    to = toValue(to)
    
    // These should be pre-calculated from node init
    var fromBoundary = [from.x-nodeAnchorOffset.x, from.y-nodeAnchorOffset.y, from.x+from.width+nodeAnchorOffset.x, from.y+nodeAnchorOffset.y+from.height]
    var toBoundary = [to.x-nodeAnchorOffset.x, to.y-nodeAnchorOffset.y, to.x+to.width+nodeAnchorOffset.x, to.y+nodeAnchorOffset.y+to.height]
    
    // Determine where the destination is relative to the source node
    let dirX = 0, dirY = 0, arwOutDir
    if (toBoundary[2] < fromBoundary[0]) {
        dirX = -1
    } else if (toBoundary[0] > fromBoundary[2]) {
        dirX = 1
    } else if (overlapX(from, to)) {
        dirX = 0
    }
    
    if (toBoundary[3] < fromBoundary[1]) {
        dirY = -1
    } else if (toBoundary[1] > fromBoundary[3]) {
        dirY = 1
    } else if (overlapY(from, to)) {
        dirY = 0
    }
    
    // Determine from where arrow will come out
    // x y
    // 0 0 - no arrow
    // 0 1 - bottom
    // 0 -1 - top
    // 1 1 - right
    // 1 -1 - right
    // 1 0 - right
    // -1 0 - left
    // -1 -1 - left
    // -1 1 - left
    if (dirX == 0 && dirY == 0) {
        return []
    } else if (dirX == 0 && dirY == 1) {
        arwOutDir = ARROW_OUT.BOTTOM
    } else if (dirX == 0 && dirY == -1) {
        arwOutDir = ARROW_OUT.TOP
    } else if (dirX == 1) {
        arwOutDir = ARROW_OUT.RIGHT
    } else {
        arwOutDir = ARROW_OUT.LEFT
    }
    
    // console.log(`\n---- ${to.id} ------`)
    // console.log(`intersectX: ${overlapX(from, to)}`)
    // console.log(`intersectY: ${overlapY(from, to)}`)
    // console.log("dir:", dirX, dirY)
    // console.log("arwOutDir:", Object.keys(ARROW_OUT)[arwOutDir])
    
    var startX, startY, endX, endY, points, offsetY = dirY, offsetX = dirX
    switch(arwOutDir) {
        case ARROW_OUT.LEFT:
        offsetY = 0
        startX = 0
        startY = from.height/2
        
        // assuming it attaches to the right of destination node
        endX = to.x - from.x + to.width 
        endY = to.y - from.y + (to.height/2)
        break
        
        case ARROW_OUT.RIGHT:
        offsetY = 0
        startX = from.width
        startY = from.height/2
        
        // assuming it attaches to the left of destination node
        endX = to.x - from.x 
        endY = to.y - from.y + (to.height/2)
        
        break
        
        case ARROW_OUT.TOP:
        offsetX = 0
        startX = from.width/2
        startY = 0
        
        // assuming it attaches to the bottom of destination node
        endX = to.x - from.x  + (to.width/2) 
        endY = to.y - from.y + to.height
        
        break
        
        case ARROW_OUT.BOTTOM:
        offsetX = 0
        startX = from.width/2
        startY = from.height
        
        // assuming it attaches to the top of destination node
        endX = to.x - from.x  + to.width/2
        endY = to.y - from.y
        
        break
    }
    
    points = [
        startX, startY,
        startX+(nodeAnchorOffset.x*offsetX), startY+(nodeAnchorOffset.y*offsetY),
        endX-(nodeAnchorOffset.x*offsetX), startY+(nodeAnchorOffset.y*offsetY),
        endX-(nodeAnchorOffset.x*offsetX), endY-(nodeAnchorOffset.y*offsetY),
        endX, endY
    ]
    
    // console.log(
    //     `\n---- ${to.attrs?.name} ------` + "\n\n" +
    //     `intersectX: ${overlapX(from, to)}` + "\n" +
    //     `intersectY: ${overlapY(from, to)}` + "\n" +
    //     "dir:", dirX, dirY + "\n" +
    //     "arwOutDir:", Object.keys(ARROW_OUT)[arwOutDir] + "\n" +
    //     `start: ${startX} ${startY}` + "\n" +
    //     `end: ${endX} ${endY}` + "\n" +
    //     `points: ${points}` + "\n\n"
    // ) 
    
    return points
}

function overlapX(a: any, b: any) {
    return (Math.abs((a.x + (a.width+nodeAnchorOffset.x*2)/2) - (b.x + (b.width+nodeAnchorOffset.x*2)/2)) * 2 <= ((a.width+nodeAnchorOffset.x*2) + (b.width+nodeAnchorOffset.x*2)));
}

function overlapY(a: any, b: any) {
    return (Math.abs((a.y + (a.height+nodeAnchorOffset.y*2)/2) - (b.y + (b.height+nodeAnchorOffset.y*2)/2)) * 2 <= ((a.height+nodeAnchorOffset.y*2) + (b.height+nodeAnchorOffset.y*2)));
}

function toValue(val: any) {
    return {
        x: typeof val.x == 'function' ? val.x() : val.x,
        y: typeof val.y == 'function' ? val.y() : val.y,
        width: typeof val.width == 'function' ? val.width() : (val.width ? val.width : val.w),
        height: typeof val.height == 'function' ? val.height() : (val.height ? val.height : val.h),
        attrs: val.attrs
    }
}

export function changeCursor(node: Node<NodeConfig>, cursor: string) {
    if (node.getStage() instanceof Konva.Stage) {
        var stg: Konva.Stage | null = node.getStage()
        if (stg) {
            stg.container().style.cursor = cursor
        }
    }
}
